import React, { useEffect, useRef, useState } from "react";
import { icons } from "../../assets/exportImgs";
import { useNavigate } from "react-router-dom";
import { FiX } from "react-icons/fi";
import {
  RxArrowRight,
  RxDashboard,
  RxExit,
  RxHamburgerMenu,
} from "react-icons/rx";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { basicProfile } from "../../api/services";
import { clearUser } from "../../stores/slices/authSlice";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { GoArrowUpRight } from "react-icons/go";
import { clearPrefData } from "../../stores/slices/JobPrefrenceSlice";
import DropDownMenu from "./DropDownMenu";
import CookieConsent from "../cookie/CookieConsent";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const token = useSelector((state) => state.auth.token);
  const [userImg, setUserImg] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(!isDropdownVisible);
    console.log("clicked");
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const dropDownHandleScroll = () => {
    setDropdownVisible(false);
  };

  useEffect(() => {
    if (isDropdownVisible) {
      window.addEventListener("scroll", dropDownHandleScroll);
    } else {
      window.removeEventListener("scroll", dropDownHandleScroll);
    }

    return () => {
      window.removeEventListener("scroll", dropDownHandleScroll);
    };
  }, [isDropdownVisible]);

  const userType = useSelector((state) => state.auth.userType);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const togglePath = (path) => {
    setDropdownVisible(false);
    navigate(path);
    setIsMobileMenuOpen(false);
  };

  const url = window.location.href.split("/").pop();

  const navigateToDashboard = () => {
    let dashboardRoute = "/dashboard";

    if (userType === "recruiter" || userType === "employer") {
      dashboardRoute = "/employer/dashboard";
    }

    navigate(dashboardRoute);
  };

  const logoutUser = () => {
    setIsMobileMenuOpen(false);
    dispatch(clearUser());
    dispatch(clearPrefData());
    navigate("/");
  };

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const displayUserNav = async () => {
    if (isAuthenticated) {
      const userInfo = await basicProfile(token);
      userInfo.data.data.image_url
        ? setUserImg(userInfo.data.data.image_url)
        : setUserImg("");
    }
  };

  useEffect(() => {
    displayUserNav();
  }, [isAuthenticated]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleProductsClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      {/* Desktop header */}
      <header
        className={`hidden xl:block z-50 w-full transition-transform duration-300 ease-in-out px-16 ${
          isScrolled ? "md:absolute top-[10px]" : "md:absolute top-[10px]"
        }`}
      >
        <div
          className={` w-full  bg-white h-[79px] shadow-2xl px-5 py-2 rounded-full container mx-auto flex items-center justify-between`}
        >
          <div className="ml-2 flex items-center gap-12">
            <img
              src={icons.CommonHeader.avua_header_logo}
              alt="header_logo"
              onClick={() => togglePath("/")}
            />
            <nav className="flex  gap-9  md:mr-5 sm:mr-0">
              <a
                onClick={handleMouseEnter}
                className="text-[#7B7B7B] text-[18px] font-outfit font-normal cursor-pointer no-underline relative group"
              >
                Products
                <span
                  className={
                    url === "employer" || url === "avua-pool-page"
                      ? `absolute h-0.5 bg-purple left-0 -bottom-1 w-full transition-all duration-300 ease-in-out`
                      : `absolute h-0.5 bg-purple left-0 -bottom-1 w-0 group-hover:w-full transition-all duration-300 ease-in-out`
                  }
                ></span>
                {isDropdownVisible && (
                  <div ref={dropdownRef}>
                    <DropDownMenu />
                  </div>
                )}
              </a>
              <a
                className="text-[#7B7B7B] text-[18px] font-outfit font-normal cursor-pointer no-underline hover:scale-105 active:scale-95 transition-all duration-100 ease-in-out relative group"
                onClick={() => togglePath("/applicant")}
              >
                Applicant
                <span
                  className={
                    url === "applicant"
                      ? `absolute h-0.5 bg-purple left-0 -bottom-1 w-full transition-all duration-300 ease-in-out`
                      : `absolute h-0.5 bg-purple left-0 -bottom-1 w-0 group-hover:w-full transition-all duration-300 ease-in-out`
                  }
                ></span>
              </a>
              <a
                className="text-[#7B7B7B] text-[18px] font-outfit font-normal  cursor-pointer no-underline hover:scale-105 active:scale-95 transition-all duration-100 ease-in-out relative group"
                onClick={() => togglePath("/about-us")}
              >
                About Us
                <span
                  className={
                    url === "about-us"
                      ? `absolute h-0.5 bg-purple left-0 -bottom-1 w-full transition-all duration-300 ease-in-out`
                      : `absolute h-0.5 bg-purple left-0 -bottom-1 w-0 group-hover:w-full transition-all duration-300 ease-in-out`
                  }
                ></span>
              </a>

              {/* <a
                className="text-[#7B7B7B] text-[18px] font-outfit font-normal  cursor-pointer no-underline hover:scale-105 active:scale-95 transition-all duration-100 ease-in-out relative group"
                onClick={() => togglePath("/blogs")}
              >
                Blogs
                <span
                  className={
                    url === "blogs"
                      ? `absolute h-0.5 bg-purple left-0 -bottom-1 w-full transition-all duration-300 ease-in-out`
                      : `absolute h-0.5 bg-purple left-0 -bottom-1 w-0 group-hover:w-full transition-all duration-300 ease-in-out`
                  }
                ></span>
              </a> */}
            </nav>
          </div>
          {isAuthenticated ? (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: "tween", duration: 0.7 }}
                className="h-10"
                onClick={() => menuToggle()}
              >
                <motion.div
                  initial={{ width: "87px", height: "45px" }}
                  animate={
                    isMenuOpen
                      ? { width: "170px", height: "135px" }
                      : { width: "87px", height: "45px" }
                  }
                  className={`flex flex-col items-start justify-between h-full bg-white border border-[#D9D9D9]  ${
                    isMenuOpen ? "rounded-xl" : "rounded-full"
                  }  overflow-hidden text-white ${
                    isMenuOpen ? "rounded-tr-none" : ""
                  } `}
                >
                  <div className="flex w-full h-full items-center justify-between px-1">
                    <div className="flex justify-end w-fit h-full">
                      <motion.div
                        initial={{ opacity: 0, rotate: 180, display: "none" }}
                        animate={
                          isMenuOpen
                            ? { opacity: 1, display: "flex", rotate: 0 }
                            : { opacity: 0, display: "none", rotate: 180 }
                        }
                        transition={{ type: "tween", duration: 0.3 }}
                        className="flex justify-center items-center"
                      >
                        <FiX
                          onClick={() => menuToggle()}
                          color="#7B7B7B"
                          size={25}
                          className="hover:scale-110 transition-all duration-200"
                        />
                      </motion.div>
                      <motion.div
                        initial={{ opacity: 0, rotate: 180, display: "none" }}
                        animate={
                          !isMenuOpen
                            ? { opacity: 1, display: "flex", rotate: 0 }
                            : { opacity: 0, display: "none", rotate: 180 }
                        }
                        transition={{ type: "tween", duration: 0.3 }}
                        className="flex justify-center items-center"
                      >
                        <RxHamburgerMenu
                          color="#7B7B7B"
                          size={22}
                          className="ml-2 hover:scale-110 transition-all duration-200"
                        />
                      </motion.div>
                    </div>
                    <div className="w-8 h-8 flex justify-center items-center">
                      {userImg !== "" ? (
                        <img
                          src={userImg}
                          className=" w-full h-full object-cover rounded-full"
                          alt="user img"
                        />
                      ) : (
                        <IoPersonCircleOutline
                          size={70}
                          className="w-fit"
                          color="#7B7B7B"
                        />
                      )}
                    </div>
                  </div>
                  <motion.div
                    initial={{ opacity: 0, display: "none" }}
                    animate={
                      isMenuOpen
                        ? { opacity: 1, display: "block" }
                        : { opacity: 0, display: "none" }
                    }
                    transition={{ type: "tween", duration: 0.3 }}
                    className="flex flex-col w-full h-fit text-[#7B7B7B] relative"
                  >
                    <div
                      onClick={() => navigateToDashboard()}
                      className="flex items-center w-full h-fit cursor-pointer hover:bg-[#F4EEFF] hover:text-[#424242] text-[16px] font-normal font-outfit leading-[24px] transition-all duration-300 p-2"
                    >
                      <div className="flex justify-between w-full active:scale-95 transition-all duration-300">
                        <RxDashboard size={24} />
                        <p className="select-none">Dashboard</p>
                      </div>
                    </div>
                    <div className="h-[1px] bg-lightPurple w-full relative" />
                    <div
                      onClick={() => logoutUser()}
                      className="flex items-center w-full h-fit cursor-pointer hover:bg-[#F4EEFF] hover:text-[#424242] text-[16px] font-normal font-outfit leading-[24px]transition-all duration-300 p-2"
                    >
                      <div className="flex justify-between w-full active:scale-95 transition-all duration-300">
                        <RxExit size={24} />
                        <p className="select-none">Logout</p>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>
            </>
          ) : (
            <div className="flex gap-5">
              <button
                onClick={() =>
                  navigate("/employer-authentication-page")
                }
                className="w-[200px] h-[59px] bg-gradient-to-r from-[#6B3BAB] to-[#2B1845] rounded-full "
              >
                <p className=" text-white font-outfit text-buttonbold font-[400]">
                  Recruit Talent
                </p>
              </button>
              <button
                onClick={() =>
                  navigate("/applicant-authentication-page")
                }
                className="w-[230px] h-[59px] border-[1px] border-[#2B1845] rounded-full "
              >
                <p className=" text-[#2B1845] font-outfit text-buttonbold font-[400] leading-[19.2px]">
                  Explore Job Opportunities
                </p>
              </button>
            </div>
          )}
        </div>
      </header>

      {/* Mobile header */}
      <header
        className={`flex items-center bg-[#F8F9FE]  justify-between xl:hidden z-50 h-[10vh] top-0 w-full transition-transform duration-300 ease-in-out px-5 ${
          isMobileMenuOpen ? "sticky bg-white border-b border-[#D9D9D9]" : ""
        }`}
      >
        <img
          onClick={() => {
            setIsMobileMenuOpen(false);
            navigate("/");
          }}
          className="cursor-pointer"
          src={icons.CommonHeader.avua_header_logo}
          alt="Logo"
        />
        <div onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "ease", duration: 0.7 }}
            >
              <FiX size={26} color={"#000000"} />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "ease", duration: 0.7 }}
            >
              <RxHamburgerMenu size={26} color={"#000000"} />
            </motion.div>
          )}
        </div>
      </header>

      {/* Mobile nav screen */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ y: -1000 }} // Slide in from the top
            animate={{ y: 0 }} // Final position
            exit={{ y: -1000 }} // Slide out to the top
            transition={{ duration: 0.55 }}
            className="bg-white w-full h-full z-40 fixed flex flex-col items-center justify-start pb-10"
          >
            <div className="flex flex-col items-start w-full space-y-2 px-5 my-10">
              {isAuthenticated && (
                <>
                  <div
                    className={`flex justify-between items-center w-[312px] ${
                      url === "dashboard" ? "bg-[#F8F9FE]" : ""
                    }`}
                  >
                    <a
                      className="block w-full text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline py-[14px] px-3 cursor-pointer rounded-xl"
                      onClick={() => navigateToDashboard()}
                    >
                      Dashboard
                    </a>
                  </div>
                </>
              )}
              <div className="relative inline-block py-[14px]">
                <a
                  className="text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline px-3 ease-in-out cursor-pointer "
                  onClick={handleProductsClick}
                >
                  Products
                  {/* <IoIosArrowDown className=" absolute top-4 left-16 ml-4 text-[20px] " /> */}
                  <IoIosArrowDown
                    className={`absolute top-[18px] left-16 ml-4 text-[20px] transition-transform duration-[500ms] ${
                      isDropdownOpen ? "rotate-180" : ""
                    }`}
                  />
                </a>
                <AnimatePresence>
                  {isDropdownOpen && (
                    <motion.div
                      className="relative mt-2 rounded-lg z-10"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div
                        className={`flex justify-between items-center w-[312px] ${
                          url === "employer" ? "bg-[#F8F9FE]" : ""
                        }`}
                      >
                        <a
                          className="block w-full text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline py-[14px] px-[16px] cursor-pointer rounded-xl"
                          onClick={() => {
                            togglePath("/employer");
                            setDropdownOpen(false);
                          }}
                        >
                          Post a Job Opening
                        </a>
                      </div>
                      <div
                        className={`flex justify-between items-center w-[312px] ${
                          url === "avua-pool-page" ? "bg-[#F8F9FE]" : ""
                        }`}
                      >
                        <a
                          className="block w-full text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline py-[14px] px-[16px] cursor-pointer rounded-xl"
                          onClick={() => {
                            togglePath("/avua-pool-page");
                            setDropdownOpen(false);
                          }}
                        >
                          avua Pool
                        </a>
                      </div>
                      <div
                        className={`flex justify-between items-center w-[312px]`}
                      >
                        <a className="block text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline py-[14px] px-[16px] cursor-pointer rounded-xl">
                          Similar CV Analysis{" "}
                          <sup className="bg-[#E7D4FF] text-[6.13px] leading-[11px] text-[#6B3BAB] px-[4px] pb-[1px] aboslute -top-2">
                            coming soon
                          </sup>
                        </a>
                      
                      </div>
                      <div
                        className={`flex justify-between items-center w-[312px]`}
                      >
                        <a className="block text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline py-[14px] px-[16px] cursor-pointer rounded-xl">
                          AI Interviews{" "}
                          <sup className="bg-[#E7D4FF] text-[6.13px] leading-[11px] text-[#6B3BAB] px-[4px] aboslute -top-2 pb-[1px]">
                            coming soon
                          </sup>
                        </a>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div
                className={`flex justify-between items-center w-full ${
                  url === "applicant" ? "bg-[#F8F9FE]" : ""
                }`}
              >
                <a
                  className="block text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline cursor-pointer rounded-xl py-[14px] px-3"
                  // style={{ marginTop: !isAuthenticated && "30px" }}
                  onClick={() => togglePath("/applicant")}
                >
                  Applicant
                </a>
              </div>

              <div
                className={`flex justify-between items-center w-full ${
                  url === "about-us" ? "bg-[#F8F9FE]" : ""
                }`}
              >
                <a
                  className="block text-[#7B7B7B] text-[16px] leading-[24px] font-outfit font-normal no-underline cursor-pointer rounded-xl py-[14px] px-3"
                  // style={{ marginTop: !isAuthenticated && "30px" }}
                  onClick={() => togglePath("/about-us")}
                >
                  About Us
                </a>
              </div>
              
            
              {/* <a
                className="text-[#7B7B7B] no-underline hover:scale-105 active:scale-95 transition-all duration-100 ease-in-out"
                onClick={() => togglePath("/recruiter")}
              >
                Recruiter
                {url === "recruiter" && (
                  <div className="absolute mt-1 w-16 h-[2px] bg-purple rounded-full" />
                )}
              </a> */}
            </div>
            {isAuthenticated ? (
              <div className="flex flex-col w-full h-full space-y-5 justify-end items-center mb-24 px-5">
                <button
                  onClick={() => logoutUser()}
                  className="w-full flex bg-gradient-to-r from-[#6B3BAB] to-[#2B1845] 
                  text-white text-[16px] leading-[19.2] font-[550] font-outfit h-14 justify-between items-center rounded-full text-center cursor-pointer transition-all duration-200 ease-linear p-[6px]"
                >
                  <div className="h-full flex justify-center items-center">
                    {userImg !== "" ? (
                      <img
                        src={userImg}
                        className="w-12 h-12 rounded-full"
                        alt="user img"
                      />
                    ) : (
                      <IoPersonCircleOutline size={45} />
                    )}
                    <p className="ml-4">Logout</p>
                  </div>
                  <RxArrowRight className="mr-4" />
                </button>
              </div>
            ) : (
              <div className="flex flex-col w-full h-full space-y-5 justify-end items-center mb-36 px-5">
                <button
                  onClick={() =>
                    navigate("/employer-authentication-page")
                  }
                  className=" bg-gradient-to-r from-[#6B3BAB] to-[#2B1845] active:scale-75 w-full text-white text-[16px] leading-[19.2] font-[550] font-outfit h-14 flex justify-center items-center rounded-full text-center cursor-pointer transition-all duration-200 ease-linear"
                >
                  Recruit Talent
                </button>
                <button
                  onClick={() =>
                    navigate("/applicant-authentication-page")
                  }
                  className="h-14 w-full text-[16px] leading-[19.2px] font-outfit flex text-[#090521] font-[550] border-[0.7px] border-[#000000] justify-center items-center rounded-full text-center cursor-pointer transition-all duration-200 ease-linear"
                >
                  Explore Job Opportunities
                </button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <CookieConsent/>
    </>
  );
};

export default Navbar;
